<template>
<v-app>
    <v-app-bar app>
        <v-img src="/img/PayConnect.png" alt="Pay-Connect" contain max-height="35" />
    </v-app-bar>
    <v-main style="padding: 0; margin-top: -15vh !important">
        <v-container fluid fill-height>
            <v-row align="center" justify="center" class="container">
                <v-img src="/img/401.png" alt="Unauthorized" contain max-height="80vh" />
                <div v-if="$route.query.errorCode" class="centered">Error code: {{$route.query.errorCode}}</div>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
export default {
    name: 'Unauthorized',
}
</script>

<style scoped>

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Centered text */
.centered {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: cornflowerblue;
  font-size: small;
  font-size: 1.5vmin;
}
</style>
